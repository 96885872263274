import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import logo from "../assets/imgs/logo-informe.svg";
import logoMd from "../assets/imgs/logo-informe-mb.svg";
import MenuLeft from "./MenuLeft";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import iconMenu from "../assets/icons/iconMenu.svg";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#E6E6E6",
    zIndex: 1500,
  },
  icon: {
    fontSize: "35px",
    stroke: "#917D26",
    strokeWidth: "0.5px",
    color: "#917D26",
  },
  button: {
    padding: "0px",
  },
  container: {
    display: "flex",
    // justifyContent: "space-around",
    // marginLeft: '200px',
    alignItems: "center",
    // [theme.breakpoints.down('sm')]: {
    //   marginLeft: '10px'
    // },
    minHeight: "70px",
  },
  logo: {
    backgroundSize: "100%",
    display: "inline-block",
    width: "93%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    marginTop: "0",
    maxWidth: "800px",
    height: "70px"
  },

  iconMenu: {
    // backgroundImage: `url(${iconMenu})`
    backgroundImage: `url(${iconMenu})`,
    display: "inline-block",
    width: "30px",
    height: "30px",
    verticalAlign: "middle",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100%",
  },
  iconS: {
    marginLeft: "-18px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-12px",

    },
  },
  iconButton:{
    maxWidth: "45px",
    maxHeight:"50px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "45px"
    },  
  },
  button:{
    position:"fixed",
    left:0,
    top:"80px",
    // backgroundColor: "#2A5080",
    zIndex: 5000,
    maxWidth: "45px",
    maxHeight:"50px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "45px"
    },
    backdropFilter: 'blur(10px) brightness(60%)',
    borderLeft: 'none',
    borderTopRightRadius : "0.3em",
    borderBottomRightRadius : "0.3em",
    border:"0.5px solid white",
    borderTopRightRadius : "0.3em",
    borderBottomRightRadius : "0.3em",
    borderLeft: 'none',
    backgroundColor: 'rgba(42,80,128,0.6)'
  },
}));

const NewHeader = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const containerRef = React.useRef(null);

  const handleDrawerSwith = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div>
        <AppBar className={classes.root} position="fixed">
          <Toolbar className={classes.container}>         
            {/*<IconButton
                      edge="start"
                      className={classes.iconS}
                      color="#6d6d6d"
                      aria-label="menu"
                      style={{ marginRight: "10px" }}
                      onClick={handleDrawerSwith}
                    >
                      
                      <span className={classes.iconMenu}></span>
                </IconButton>*/}
            <a style={{ display: "flex" }} href='https://comisiondelaverdad.co/'>
              <img
                className={classes.logo}
                src={matches ? logoMd : logo}
                alt="logo"
              />
            </a>
          </Toolbar>
        </AppBar>
        <div    ref={containerRef}>
        <Slide direction="right" timeout={200} in={!open}  container={containerRef.current}>
            <div   className={classes.button}>
            <IconButton  className={classes.iconButton}
                    onClick={handleDrawerOpen} >
              <ArrowRightIcon style={{ color: "white" }}  fontSize="large"  />
            </IconButton>
            </div>
          </Slide>
        <MenuLeft
          section={2}
          handleDrawerClose={handleDrawerClose}
           open={open}
        ></MenuLeft>
      </div>
        {/* {matches ? (
          <MenuLeft
            section={2}
            handleDrawerClose={handleDrawerClose}
            open={open}
          ></MenuLeft>
        ) : (
          // <MenuLeftDesktop
          //   section={2}          
          //   open={open}
          //   newLayout={true}

          // />
          
        )} */}
      </div>
    </>
  );
};

export default withWidth()(NewHeader);
