import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./store/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";

import { unregister } from './serviceWorkerRegistration'


i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources: {
    es: {
      common: common_es,
    },
    en: {
      common: common_en,
    },
  },
});

const { PUBLIC_URL } = process.env;


ReactDOM.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  </I18nextProvider>
  ,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
