import { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Drawer from "@material-ui/core/Drawer";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core";
import * as MenusService from "../../services/MenusService";
import ListSubheader from "@material-ui/core/ListSubheader";
import ItemMenu from "./ItemMenu";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { connect } from "react-redux";
import { FlashOffTwoTone } from "@material-ui/icons";

const menuJson = {
  _id: { $oid: "626013358d54b911aafc6220" },
  elements: [
    {
      tag: "Buscador",
      route: "explora/buscador",
      tab: false,
      id: "d05fbe5a-8674-4ad9-b4fa-60e529b35216",
      img: "",
      clases: "separador",
      icon: 'buscador.js',
      only: false,
      // material_icon: "ExploreTwoTone",
      orden: 1,
      // children: [
      //   {
      //     tag: "buscador",
      //     route: "explora/buscador",
      //     tab: false,
      //     id: "e87b8667-cad3-4ee3-8a5e-7d7079132042",
      //     img: "",
      //     icon: null,
      //     only: false,
      //     material_icon: "",
      //     clases: "",
      //     orden: 5,
      //     children: [],
      //   },
      // ],
    },
    {
      tag: "Tesauro del archivo",
      route: "etiquetario",
      tab: false,
      id: "143a41b1-100f-4ee7-ac87-bcbc608e75a8",
      img: "",
      icon: 'tesauro.js',
      only: false,
      material_icon: "",
      clases: "",
      orden: 2,
      children: [],
    },
    {
      tag: "Fondos documentales",
      route: "como-navegar-el-archivo?bloque=2",
      tab: false,
      id: "143a41b1-100f-4ee7-ac87-bcbc608r75a8",
      img: "",
      icon: 'tree.js',
      only: false,
      material_icon: "",
      clases: "",
      orden: 2,
      children: [],
    },
    {
      tag: "Ir a colecciones",
      route: "colecciones",
      tab: false,
      id: "143a41b1-100f-4ee7-ac87-bcbc608e75a8",
      img: "",
      icon: 'colecciones.js',
      only: false,
      material_icon: "",
      clases: "",
      orden: 2,
      children: [],
    },
    // {
    //   tag: "¿Cómo navegar en el archivo?",
    //   route: "https://drive.google.com/file/d/1wtTk9O5ek1L8P_cba4iVXKk4aLpdydOu/view?usp=sharing",
    //   out: true,
    //   tab: false,
    //   id: "143a41b1-100f-4ee7-ac87-bcbc608e75a8",
    //   img: "",
    //   icon: 'como-navegar.js',
    //   only: false,
    //   material_icon: "",
    //   clases: "",
    //   orden: 2,
    //   children: [],
    // },
    {
      tag: "¿Qué es el archivo?",
      route: "que-es-el-archivo",
      tab: false,
      id: "143a41b1-100f-4ee7-ac87-bcbc608e75a8",
      img: "",
      icon: 'pregunta-icon.js',
      only: false,
      material_icon: "",
      clases: "",
      orden: 2,
      children: [],
    },
    {
      tag: "¿Cómo navegar en el archivo?",
      route: "como-navegar-el-archivo",
      tab: false,
      id: "143a41b1-100f-4ee7-ac87-bcbc608e75a8",
      img: "",
      icon: 'como-navegar.js',
      only: false,
      material_icon: "",
      clases: "",
      orden: 2,
      children: [],
    },
    {
      tag: "Transmedia Digital",
      out: true,
      route: "",
      tab: false,
      route: "https://comisiondelaverdad.co/",
      out: true,
      id: "143a41b1-100f-4ee7-ac87-bcbc608e75a8",
      img: "",
      icon: 'transmedia.js',
      only: false,
      material_icon: "",
      clases: "separador",
      orden: 2,
      children: [],
    },
    // {
    //   tag: "Informe Final",
    //   route: "",
    //   tab: false,
    //   id: "143a41b1-100f-4ee7-ac87-bcbc608e75a8",
    //   img: "",
    //   icon: 'informe_final.js',
    //   only: false,
    //   material_icon: "",
    //   clases: "",
    //   orden: 2,
    //   children: [],
    // },
    
    // {
    //   tag: "Mi biblioteca",
    //   route: "museo/crea/mi-biblioteca",
    //   tab: false,
    //   id: "b6e82cc1-bb5e-4fb0-a4d4-c7fe50d73a4e",
    //   img: "",
    //   icon: null,
    //   only: false,
    //   material_icon: "BookTwoTone",
    //   clases: "",
    //   orden: 3,
    //   children: [],
    // },
    // {
    //   tag: "Salir",
    //   route: "logout",
    //   tab: false,
    //   id: "b44be534-76ac-45e6-9d5b-a60f0b9788f2",
    //   img: "",
    //   icon: null,
    //   only: false,
    //   material_icon: "ExitToAppTwoTone",
    //   clases: "",
    //   orden: 4,
    //   children: [],
    // },
  ],
  nombreMenu: "Menú de archivo",
  estado: 1,
  section: 2,
  __v: 0,
};

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  cardMenu: {
    backgroundColor: "#2A5080",
    borderRadius: "unset",
    height: "56px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    // justifyContent: "flex-end",
    justifyContent: "space-between",
    color: "white",
  },
  divider: {
    backgroundColor: "#917d26",
    height: "4px",
  },
  profileName: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    // backdropFilter: 'blur(20px)',
    background: 'none !important',
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1491,
    // background: 'none !important',
    backdropFilter: 'blur(15px) brightness(60%)',
    borderRight: '1px solid white',
    backgroundColor: 'rgba(42,80,128,0.6)'
  },
  iconButton:{
    maxWidth: "45px",
    maxHeight:"50px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "45px"
    },  
  },
  button:{
    position:"fixed",
    left:drawerWidth,
    top:"80px",
    // backgroundColor: "#2A5080",
    zIndex: 5000,
    maxWidth: "45px",
    backdropFilter: 'brightness(60%)',
    maxHeight:"50px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "45px"
    },
    border:"0.5px solid white",
    borderTopRightRadius : "0.3em",
    borderBottomRightRadius : "0.3em",
    borderLeft: 'none',
    backgroundColor: 'rgba(42,80,128,0.6)'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root: {
    width: "100%",
    maxWidth: 300,
    // backgroundColor: theme.palette.background.paper,
    background: 'none !important',

    '& .separador': {
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(4),
      borderTop: '1px dashed rgba(255,255,255,0.1)'
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  media: {
    height: 140,
  },
  titleProfile: { marginLeft: "8px", marginBottom: "0px" },
}));

const MenuLeft = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [elements, setElements] = useState([]);
  const [active, setActive] = useState();
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    // loadItems(props.section);
    LoadItemJson();
  }, []);

  useEffect(() => {
   
  }, [props.open]);



  const getName = () => {
    if (user.name) {
      let name = user.name.split(" ");
      if (name.length == 2) return name[0] + " " + name[1];
      else if (name.length >= 3) return name[0] + " " + name[2];
      else return name[0];
    }
    return "Username";
  };

  const activate = (id) => setActive(id);

  const handleDrawerClose = () => {    
    if(openDrawer){    
      props.handleDrawerClose(false);
      setOpenDrawer(false);
    }else{
      setOpenDrawer(true)
    }
  };
  const loadItems = async (section) => {
    let res = await MenusService.getBySection(section);
    if (res.elements) {
      setElements(res.elements);
    }
  };

  const LoadItemJson = () => {
    setElements(menuJson.elements);
  };

  const classes = useStyles();
  const renderItems = elements.map((item, index) => (
    <ItemMenu activate={activate} active={active} key={index} item={item} />
  ));

  return (
    <>
      {
        <>
          <Slide direction="right" in={props.open} mountOnEnter unmountOnExit>
          <div   className={classes.button}>
            <IconButton  className={classes.iconButton}>
              <ArrowLeftIcon style={{ color: "white" }}  fontSize="large"  />
            </IconButton>
            </div>
        </Slide>
        <ClickAwayListener touchEvent="false" onClickAway={handleDrawerClose}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div>
            <Card className={classes.cardMenu}>
              <div className={classes.drawerHeader}>
                <Typography
                  className={classes.titleProfile}
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  {/* {getName()} */}
                </Typography>
                {/*<IconButton
                  // style={{ marginBottom: "0px" }}
                  onClick={handleDrawerClose}
                >
                  <ArrowLeftIcon style={{ color: "white" }} fontSize="large" />
        </IconButton>*/}
              </div>
            </Card>
          </div>
          <Divider className={classes.divider} />

          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
              ></ListSubheader>
            }
            className={classes.root}
          >
            {renderItems}
          </List>
        </Drawer>
        </ClickAwayListener>
        </>
      }
    </>
  );
};

export default MenuLeft;

// export default MenuLeft;
